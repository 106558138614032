import { DEFAULT_SERVICE, getProfilingData } from './profiling';
import { getApi as getNewsletterApi, getNewsletterCode } from '../newsletter/api';

const pianoCloseButtonSelector = '.tp-modal .tp-active .tp-close';
const pianoCallbackEndpoint = '/piano/callback';
const pianoLogoutEndpoint = '/piano/logout';
const location = window.location;
const PIANO_API_ORIGIN = window.globals?.PIANO_API_ORIGIN || '';
const PROFILING_STEP_ONE_FORM_ID = 'profiling_step_1';

window.tp = window.tp || [];

const pianoIdInitLogin = (containerSelector) => {
  console.log('pianoId init login', 'B2')
  const options = {
    loggedIn: async function (data) {},
    loggedOut: function () {},
  };
  if (containerSelector) {
    options.containerSelector = containerSelector;
    options.displayMode = 'inline';
  }
  console.log('pianoId show', 'B3')
  window.tp.pianoId.show(options);
};

const pianoIdInitLogout = () => {
  window.tp.pianoId.logout(async function () {
    try {
      const resp = await fetch(pianoLogoutEndpoint);
      const json = await resp.json();
      if (json.logout === 'OK') {
        location.reload();
      }
    } catch (e) {
      console.error(e.message);
    }
  });
};

/**
 * Show Piano login and registration form, or perform silent login.
 * @param {string} containerSelector if present, will open login in a dom element, rather than in a modal.
 */
const startPianoLogin = (containerSelector) => {
  console.log('start piano login', 'B1')
  window.tp.push(['init', () => pianoIdInitLogin(containerSelector)]);
};

const pianoLogout = () => {
  window.tp.push([
    'init',
    () => {
      pianoIdInitLogout();
    },
  ]);
};

const showPianoPendingWidget = () => {
  // TODO is there a better way to show confirmation required screen?
  const tp = window.tp || [];
  tp.push([
    'init',
    function () {
      tp.pianoId.logout();
      tp.pianoId.show({
        screen: 'email_confirmation_required',
      });
      document.addEventListener('click', (ev) => {
        if (ev.target === document.querySelector('.tp-modal .tp-active .tp-close')) {
          setTimeout(startPianoLogin, 100);
        }
      });
    },
  ]);
};

const updateProfilingStepOne = async (data) => {
  const tp = window.tp || [];
  const custom_fields = [];
  for (const [name, value] of Object.entries(data)) {
    if (value) {
      custom_fields.push({
        field_name: name,
        value: value,
      });
    }
  }
  await fetch(
    `${PIANO_API_ORIGIN}/id/api/v1/identity/userinfo?aid=${
      tp.aid
    }&access_token=${tp.pianoId.getToken()}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        uid: tp.pianoId.getUser().uid,
        form_name: PROFILING_STEP_ONE_FORM_ID,
        custom_field_values: custom_fields,
      }),
    },
  );
};

const callback = async () => {
  const tp = window.tp || [];
  const resp = await fetch(`${pianoCallbackEndpoint}?token=${tp.pianoId.getToken()}`);
  return await resp.json();
};

window.tp.push([
  'addHandler',
  'loginSuccess',
  async function (data) {
    // const tp = window.tp || [];
    if (data.registration) {
      const profilingData = getProfilingData();
      await updateProfilingStepOne(profilingData);
    }
    try {
      await callback().catch((err) => {
        console.error(err);
        // this remove __utp reloading the page. Maybe specialization of the error handling could be done in the future.
        // tp.pianoId.logout();
      });
      if (data.registration && !data.params.email_confirmation_required) {
        // subscribe to newsletter if email is confirmed and user just registered
        try {
          await getNewsletterApi()
            .subscribe()
            .catch((err) => {
              // this is an error in subscribe
              console.error(err);
            });
        } catch (err) {
          // this is an error in getNewsletterApi
          console.debug(err);
        }
      }
      const closeButton = document.querySelector(pianoCloseButtonSelector);
      if (closeButton === null) {
        location.reload();
      } else {
        closeButton.addEventListener('click', () => location.reload());
      }
    } catch (e) {
      console.error(e.message);
    }
  },
]);

const verifyEmailHandler = async (event) => {
  if (!event.origin.match(/tinypass\.com$/)) {
    return;
  }
  let data;
  if (typeof event.data === 'string') {
    data = JSON.parse(event.data);
  } else {
    data = event.data;
  }

  if (!data.sender.startsWith('piano-id')) {
    return;
  }
  if (
    data.event !== 'verifyCode' &&
    !(data.event === 'changeScreen' && data.params.screen === 'email_confirmation')
  ) {
    return;
  }
  const userinfo = await window.authSession;
  if (userinfo.email_verified) {
    // This is because just after the user verifies email,
    // authSession isn't updated yet. It will be updated with callback.
    // And it needs to be updated because scorpion token with email_verified
    // is required by owlery.
    // If in authSession email_verified is already true, it means that we
    // already handled this subscription.
    console.debug('User already had email verified. Not subscribing');
    return;
  }
  const tp = window.tp || [];
  tp.pianoId.loadExtendedUser({
    extendedUserLoaded: async function (data) {
      const fields = {};
      for (const i in data.custom_field_values) {
        const fieldName = data.custom_field_values[i].field_name;
        const fieldValue = data.custom_field_values[i].value;
        fields[fieldName] = fieldValue;
      }
      if (fields.primary_service === DEFAULT_SERVICE) {
        await callback(); // update jwt in cookie
        window.authSessionReload(); // reload jwt that will be used by newsletter
        const userinfoAfterVerify = await window.authSession;
        if (userinfoAfterVerify.email_verified) {
          const api = getNewsletterApi(
            getNewsletterCode(fields.primary_edition, fields.primary_domain),
          );
          api.subscribe();
        }
      }
    },
    formName: 'profiling_step_1',
  });
};

window.addEventListener('message', verifyEmailHandler, false);

export { startPianoLogin, pianoLogout, pianoIdInitLogout, showPianoPendingWidget, callback };
