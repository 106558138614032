const DEFAULT_SERVICE = 'community'; // by convention

const getEdition = () => {
  const MonrifPolopolyUtility = window.MonrifPolopolyUtility;
  if (typeof MonrifPolopolyUtility !== 'undefined' && 'edizione' in MonrifPolopolyUtility) {
    return MonrifPolopolyUtility.edizione.externalId;
  }
};

const getDomainName = () => {
  const MonrifPolopolyUtility = window.MonrifPolopolyUtility;
  return typeof MonrifPolopolyUtility !== 'undefined' && 'site' in MonrifPolopolyUtility
    ? MonrifPolopolyUtility.site.split('.')[2]
    : 'qn';
};

const getProfilingData = () => {
  return {
    primary_service: DEFAULT_SERVICE,
    primary_edition: getEdition(),
    primary_domain: getDomainName(),
    registration_continue_url: location.href,
  };
};

export { getProfilingData, DEFAULT_SERVICE, getEdition, getDomainName };
