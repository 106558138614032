import jwt_decode from 'jwt-decode';
import { callback } from './login/pianoCallbacks';

import { getCookie } from './utility';

if (typeof window.getCookie != 'function') {
  window.getCookie = getCookie;
}

const arrayConsentsPianoId = ['marketing_consent', 'community_terms'];
const mapConsents = {
  community_terms: 'profiling',
};

/*
 * Easy implementation using disqus cookie. Not reliable and not secure.
 */

var USER_DATA_STORAGE_KEY = 'userData';
var userDataStorage = {
  setData: function (data) {
    var raw_data = data;
    if (typeof data === 'object') {
      raw_data = JSON.stringify(data);
    }
    localStorage.setItem(USER_DATA_STORAGE_KEY, raw_data);
  },
  getData: function () {
    return JSON.parse(localStorage.getItem(USER_DATA_STORAGE_KEY));
  },
  clearData: function () {
    localStorage.removeItem(USER_DATA_STORAGE_KEY);
  },
};

function isConsent(element, index, array) {
  return arrayConsentsPianoId.some((nameConsent) => element.field_name === nameConsent);
}

function filterField(array, name) {
  return array.filter((field) => field.field_name === name);
}

function getLoginStatus(isUserValid, emailConfirmed, paymentStatus) {
  if (!isUserValid) return 'Anonymous';
  if (!emailConfirmed) return 'Pending';
  if (!paymentStatus) return 'Registered';
  return paymentStatus;
}

function setCookie(name, value) {
  const expires = 7 * 24 * 60 * 60;
  document.cookie = `${name}=${value}; path=/; max-age=${expires}`;
}

function authSessionInit(resolve, reject) {
  console.log('authSession init', 'D1');
  let userInfo = {};
  window.tp = window.tp || [];
  window.tp.push([
    'init',
    () => {
      console.log('authSession tp init', 'D2');
      try {
        tp.api.callApi('/access/list', {cross_app: true}, async function (userAccesses) {
          console.log('tp api access/list callback', 'D3');
          // code: 2004
          // message: "User not found"
          if (userAccesses.code === 2004) {
            userInfo.loginStatus = 'Anonymous';
            resolve(userInfo);
          }
          const dataUserPianoId = tp.pianoId.getUser();
          if (dataUserPianoId !== null) {
            const {
              family_name = '',
              firstName = '',
              given_name = '',
              lastName = '',
            } = dataUserPianoId;
            userInfo = { ...userInfo, family_name, firstName, given_name, lastName };
            userInfo.email = dataUserPianoId.email;
            const cookie = getCookie(JWT_COOKIE);
            if (cookie !== '') {
              const cookieDecoded = jwt_decode(cookie);
              const emailNotConfirmedByPiano = dataUserPianoId.email_confirmation_required;
              const emailNotConfirmedByCookie = !cookieDecoded.email_verified;
              // if there is anonymous session on scorpion and logged-in user on piano, refresh token
              if (emailNotConfirmedByPiano !== emailNotConfirmedByCookie) callback();
              userInfo.id_hash = cookieDecoded.id_hash;
            } else userInfo.id_hash = dataUserPianoId.sub;
            userInfo.sub = dataUserPianoId.sub;
            userInfo.email_verified = !dataUserPianoId.email_confirmation_required;
            const hasCapabilities = userAccesses?.data?.length;
            // In Piano, related to Digital Edition, all the resources start by 'DE_'
            const isSfogliatoreUser =
              userAccesses.data &&
              userAccesses.data.some((access) => access.resource.rid.substring(0, 3) === 'DE_');
            if (hasCapabilities)
              userInfo.paymentStatus = isSfogliatoreUser ? 'Sfogliatore' : 'Subscriber';
            else userInfo.paymentStatus = '';
            const isUserValid = tp.pianoId.isUserValid();
            userInfo.loginStatus = getLoginStatus(
              isUserValid,
              userInfo.email_verified,
              userInfo.paymentStatus,
            );
          } else userInfo.loginStatus = 'Anonymous';
          setCookie('loginStatus', userInfo.loginStatus);
          console.log('tp api access/list with userInfo', 'D4', userInfo);
          const updateStatus = new Event('update-user-status');
          document.dispatchEvent(updateStatus);
          await tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
                userInfo.newsletterUser =
                  filterField(data.custom_field_values, 'newsletter_id').length > 0;
                const primaryEditionField = filterField(data.custom_field_values, 'primaryEdition');
                if (primaryEditionField.length) userInfo.primaryEdition = primaryEditionField.value;
                userInfo.consent = [];
                const arrayConsents = data.custom_field_values.filter(isConsent);
                const mapConsentsKeys = Object.keys(mapConsents);
                arrayConsents.forEach((field) => {
                  const nameConsent = mapConsentsKeys.includes(field.field_name)
                    ? mapConsents[field.field_name]
                    : field.field_name;
                  return userInfo.consent.push(nameConsent);
                });
              }
              resolve(userInfo);
            },
            formName: 'profiling_step_1',
          });
          console.log('tp api access/list after loadExtendedUser async call', 'D5');
        });
      } catch (e) {
        console.log('tp api access/list catch error', 'D6');
        userInfo.error = e.message;
        reject(userInfo);
      }
    },
  ]);
}

window.authSession = new Promise(authSessionInit);
window.userDataStorage = userDataStorage;
window.authSessionReload = function () {
  window.authSession = new Promise(authSessionInit);
};
