class NewsletterAPI {
  constructor(API_ORIGIN, code) {
    if (typeof API_ORIGIN !== 'string') {
      throw new Error('Newsletter API_ORIGIN: expected a string, was ' + typeof API_ORIGIN);
    }
    if (typeof code !== 'string') {
      throw new Error('Newsletter code: expected a string, was ' + typeof code);
    }
    this.API_ORIGIN = API_ORIGIN;
    this.code = code;
    this.cookie = window.getCookie(window.globals.JWT_COOKIE_NAME);
    if (typeof this.cookie !== 'string') {
      throw new Error('Session cookie is not set');
    }
  }

  getHeaders() {
    return {
      Authorization: 'Bearer ' + this.cookie,
      'Content-Type': 'application/json',
    };
  }

  async getSubscriptions() {
    return new Promise((resolve, reject) => {
      fetch(this.API_ORIGIN + '/api/subscriptions/', { headers: this.getHeaders() })
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              resolve(data);
            });
          } else {
            reject(new Error('Si è verificato un errore'));
          }
        })
        .catch((err) => {
          reject(new Error(err.message));
        });
    });
  }

  async isSubscribed() {
    const subscriptions = await this.getSubscriptions();
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i].newsletter_code === this.code) {
        return true;
      }
    }
    return false;
  }

  async subscribe() {
    return new Promise((resolve, reject) => {
      fetch(this.API_ORIGIN + '/api/subscriptions/subscribe/', {
        method: 'POST',
        headers: this.getHeaders(),
        body: JSON.stringify({
          newsletter_code: this.code,
        }),
      })
        .then((response) => {
          if (response.ok) {
            resolve(true);
          } else {
            reject(new Error('Subscription error'));
          }
        })
        .catch((err) => {
          reject(new Error(err.message));
        });
    });
  }
}

const getNewsletterCode = (edition, domain) => {
  if (typeof edition !== 'undefined') {
    return edition;
  }
  if (typeof domain !== 'undefined') {
    switch (domain) {
      case 'qn':
        return 'monrif.site.qn';
      case 'ilresto':
        return 'monrif.page.ilresto.bologna';
      case 'lanazione':
        return 'monrif.page.lanazione.firenze';
      case 'ilgiorno':
        return 'monrif.page.ilgiorno.milano';
    }
  }
};

const getNewsletterCodeFromPage = () => {
  const MonrifPolopolyUtility = window.MonrifPolopolyUtility;
  if (typeof MonrifPolopolyUtility === 'undefined') {
    throw new Error('MonrifPolopolyUtility is required to get newsletter code');
  }
  if (
    typeof MonrifPolopolyUtility.loginNewsletter === 'undefined' ||
    MonrifPolopolyUtility.loginNewsletter !== 'True'
  ) {
    // Don't init newsletter
    throw new Error('Newsletter not enabled in CMS in this page');
  }
  return MonrifPolopolyUtility.page;
};

const getApi = (newsletterCode) => {
  if (typeof newsletterCode === 'undefined') {
    newsletterCode = getNewsletterCodeFromPage();
  }
  return new NewsletterAPI(window.globals.NEWSLETTER_ORIGIN, newsletterCode);
};

export { getApi, getNewsletterCode };
